import { useAuth0 } from '@auth0/auth0-react'
import { Box } from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import useLocalStorageState from 'use-local-storage-state'
import { useSearchParams } from 'react-router-dom'
import { Props as FormRendererProps } from 'components/FormRenderer/FormRenderer'
import {
    useGetFormDefinition,
    useSaveSubmissionMutation,
    getFormSubmissionRequest,
    useCreateSubmissionMutation,
    FormSubmissionCreateResponseData,
    useGetFormById,
    useUpdateFormSubmissionProgress,
} from 'api/api'
import FormRenderer from 'components/FormRenderer/FormRenderer'
import { routes } from 'routes'
import { customOptions, customScriptUrl } from 'utils/formConfig'
import messageParent, { MESSAGE_TYPE } from 'utils/message'
import { useUrlQueryParams } from 'utils/query'
import logo from 'assets/logo.png'
import './fill-form.scss'
import axios from 'axios'
import getClient from 'api/api-utils'
import { decodedEmbeddedJwtToken } from 'utils/auth'

const pageHashPrefix = '#page-'
const pageFromHash = hash => {
    if (hash && hash.startsWith(pageHashPrefix)) {
        const page = Number(hash.substring(pageHashPrefix.length))
        if (Number.isInteger(page)) {
            return page
        }
    }
}

const getDetailedMessageStr = (err: any): string | undefined => {
    if (axios.isAxiosError(err)) {
        return err.response?.data?.message
    } else if (err && typeof err.message == 'string') {
        return err.message
    }
    return undefined
}

const authedUserIdentity = (): string | null => {
    // A client will pass the bearer token through a WebView/iframe
    // Return that token's user (`sub` key)
    // Note that we don't need to validate it and should not depend on it to be an actual user ID
    // (it won't be, for an auth0 identity)
    // because the backend will decode the authed user from it
    try {
        const decoded = decodedEmbeddedJwtToken()
        return decoded ? decoded.sub : null
    } catch (e) {
        console.error(e)
    }
    return null
}

/**
 * Allows a member to fill out a form -> updates a FormSubmission object on the backend
 * Creates a new response if one does not already exist
 *
 * TODO: force the member to use the most recent FormDefinition if they began the form on an older one
 * https://github.com/fireflyhealth/lucian/blob/9e0b38cbc6e65e969c6f847a603353c37cbcb041/forms-ui/src/pages/FillForm/FillForm.jsx#L265-L277
 */
const FillForm = () => {
    const navigate = useNavigate()
    const [searchParams, _] = useSearchParams()
    const { hash } = useLocation()
    const { formId, id: formSubmissionId } = useParams()
    const { renderMode } = useUrlQueryParams({
        renderMode: 'form',
    })

    const { getAccessTokenSilently } = useAuth0()
    const [submitted, setSubmitted] = useState(false)
    const [loading, setLoading] = useState({})
    const [error, setError] = useState<undefined | FormRendererProps['error']>(undefined)
    const [sectionHistory, setSectionHistory] = useState({})
    // An existing submission is only loaded when this component is being used
    // to load a specific FormSubmission i.e. by passing a FormSubmission ID
    // Otherwise, we should always be creating a new one
    const [formSubmission, setFormSubmission] = useState<null | FormSubmissionCreateResponseData>(
        null,
    )
    const { data: form } = useGetFormById(parseInt(formId!))

    const { data: formDefinition } = useGetFormDefinition({
        id: formSubmission?.form_definition || form?.current_form_definition_id || undefined,
    })

    // Local storage keeps track of state e.g. which page of the form we're
    const [activeFormSubmissionStates, setActiveFormSubmissionStates] = useLocalStorageState(
        `activeFormSubmissionStates-${authedUserIdentity()}`,
        {},
    )
    const activeFormSubmissionState = useMemo(() => {
        if (activeFormSubmissionStates && form && activeFormSubmissionStates[form.id]) {
            return activeFormSubmissionStates[form.id]
        }
    }, [activeFormSubmissionStates, formId])

    const createSubmissionMutation = useCreateSubmissionMutation()
    const saveSubmissionMutation = useSaveSubmissionMutation()
    const updateFormSubmissionProgressMutation = useUpdateFormSubmissionProgress(formSubmission?.id)

    const options = customOptions()
    const script = useMemo(() => customScriptUrl(), [])
    const pageParam = useMemo(() => pageFromHash(hash), [hash])

    const saveFormState = (formId: number | string, details: { page?; id? } | null) => {
        setActiveFormSubmissionStates(value => ({
            ...value,
            [formId]: details || undefined,
        }))
    }

    const resetSubmission = async () => {
        if (!formId || !formSubmission) return
        saveFormState(formId, {})
        setSubmitted(false)
        if (formSubmission?.id) {
            const response = await saveSubmissionMutation.mutateAsync({
                data: { ...formSubmission.data, completed_at: null, data: {} },
                submissionId: formSubmission.id,
            })
            setFormSubmission(response.data)
            setUrl(formSubmission.id, activeFormSubmissionState.page)
        }
        setError(undefined)
    }

    const cancelSubmissionHandler = async () => {
        if (!formSubmission?.id) return
        try {
            await saveSubmissionMutation.mutateAsync({
                data: { ...formSubmission.data, data: {} },
                submissionId: formSubmission.id,
            })
        } catch (err) {
            setError({
                message: 'Failed to cancel',
                detailedMessage: getDetailedMessageStr(err),
            })
        }

        messageParent(MESSAGE_TYPE.FORM_SUBMISSION_CANCELED)
        resetSubmission()
    }

    const sendCancelSubmission = () => {
        messageParent(MESSAGE_TYPE.FORM_SUBMISSION_CANCELED)
    }

    // setting up custom close option if available
    if (options.configureAdditionalCustomExit) {
        options.configureAdditionalCustomExit(sendCancelSubmission)
    }

    const restartSubmissionHandler = () => {
        messageParent(MESSAGE_TYPE.FORM_SUBMISSION_RESTARTED)
        resetSubmission()
    }

    const newSubmissionHandler = () => {
        messageParent(MESSAGE_TYPE.FORM_SUBMISSION_ACKNOWLEDGED)
        if (options.confirmationDoneSkipRestart) {
            return
        }
        resetSubmission()
    }

    const setUrl = (formSubmissionId, page) => {
        const pathname =
            routes.responseEdit(form?.id, formSubmissionId, 0) +
            (searchParams ? `?${searchParams}` : '')
        const hash = page ? `${pageHashPrefix}${page}` : ''
        navigate(pathname + hash)
    }

    // Resume ongoing submission from localstorage
    useEffect(() => {
        if (activeFormSubmissionState) {
            setUrl(activeFormSubmissionState.id, activeFormSubmissionState.page || 0)
            return
        }
    }, [formSubmissionId, activeFormSubmissionState])

    const fetchData = async () => {
        if (!formSubmissionId) {
            console.log('[FillForm] fetchData called with no formSubmissionId')
        }
        try {
            setLoading(prevState => ({ ...prevState, draft: true }))
            const client = await getClient({ getAccessTokenSilently })
            const response = await getFormSubmissionRequest(client, formSubmissionId)
            console.log('Found FormSubmission', response.data.id)
            setFormSubmission(response.data)
            if (response.data.completed_at) {
                setError({
                    message: 'This response is closed and cannot be edited',
                })
            } else {
                messageParent(MESSAGE_TYPE.FORM_SUBMISSION_RESUMED, {
                    questionnaireResponseId: formSubmissionId,
                    formId: form?.id,
                })
            }

            setLoading(prevState => ({ ...prevState, draft: false }))
        } catch (err) {
            if (axios.isAxiosError(err) && err.response?.status && err.response.status >= 400) {
                resetSubmission()
            } else {
                setError({
                    message: 'Failed to resume',
                    detailedMessage: getDetailedMessageStr(err),
                    invalidResponseId: formSubmissionId,
                })
            }
        }
    }

    // Fetch draft response data if id specified
    useEffect(() => {
        if (!formSubmissionId) {
            return
        }

        fetchData()
    }, [formSubmissionId, getAccessTokenSilently, formId])

    // Create a new FormSubmission
    useEffect(() => {
        if (submitted || formSubmissionId) {
            return
        }

        const initialize = async () => {
            if (!form?.current_form_definition_id) return
            try {
                setLoading(prevState => ({ ...prevState, questionnaireInstance: true }))
                const response = await createSubmissionMutation.mutateAsync({
                    formId: form.id,
                    formDefinitionId: form.current_form_definition_id,
                })
                console.log('Created new FormSubmission', response.id)
                messageParent(MESSAGE_TYPE.FORM_SUBMISSION_CREATED, {
                    questionnaireResponseId: response.id,
                    formId: form.id,
                })
                setUrl(response.id, 0)
                setLoading(prevState => ({ ...prevState, questionnaireInstance: false }))
            } catch (err) {
                setError({
                    message: 'Failed to prepare form',
                    detailedMessage: getDetailedMessageStr(err),
                })
            }
        }
        initialize()
    }, [navigate, submitted, formSubmissionId, activeFormSubmissionState, form])

    // Sync current submission id to url and localstorage
    useEffect(() => {
        if (!formSubmissionId || !form) {
            return
        }

        setUrl(formSubmissionId, pageParam)
        saveFormState(form.id, {
            id: formSubmissionId,
            page: pageParam,
        })
    }, [form, pageParam, formSubmissionId])

    const handleSubmit = async (submissionData: { data: any }) => {
        setLoading(prevState => ({ ...prevState, submit: true }))
        try {
            const response = await saveSubmissionMutation.mutateAsync({
                data: {
                    data: submissionData.data,
                    completed_at: new Date().toISOString(),
                },
                submissionId: parseInt(formSubmissionId!),
            })
            saveFormState(formId!, null)
            setSubmitted(true)

            messageParent(MESSAGE_TYPE.FORM_SUBMISSION_COMPLETED, {
                questionnaireResponseId: formSubmissionId,
                formId: form?.id,
                data: response.data.data,
                submissionResponse: { id: response.data.id },
            })
            if (typeof (window as any).confirmationPageLogic === 'function') {
                ;(window as any).confirmationPageLogic(submissionData.data)
            }
        } catch (err) {
            setError({
                message: 'Failed to save response',
                detailedMessage: getDetailedMessageStr(err),
            })
        }
        setLoading(prevState => ({ ...prevState, submit: false }))
    }

    // Scrolls the page to the top
    const scrollUp = () => {
        document.querySelector('html')?.scrollTo({ top: 0 })
    }

    // Sets the current subpage as progress for the section
    const setSectionProgress = pageNr => {
        const foundSection = Object.keys(sectionHistory)
            .reverse()
            .find(section => pageNr >= parseInt(section, 10))
        if (foundSection) {
            setSectionHistory(prevState => ({
                ...prevState,
                [foundSection]: pageNr,
            }))
        }
    }

    const handlePageChange = async (page: number, submission) => {
        setUrl(formSubmissionId, page)
        setSectionProgress(page)
        await updateFormSubmissionProgressMutation.mutateAsync({
            pageId: page,
            rawData: submission.data,
        })
        scrollUp()
    }

    const loadingValues = {
        ...loading,
        existing: !!(formSubmissionId && !formSubmission),
    }

    const isLoading = () => Object.values(loadingValues).some(val => val)

    const handleOnWizardPageSelected = props => {
        const sectionIdx = props.root.allPages.indexOf(props)
        const visitedSubpage = sectionHistory[sectionIdx]
        if (visitedSubpage !== undefined) {
            setUrl(formSubmissionId, visitedSubpage)
        }
        scrollUp()
    }

    const onFormReady = formInstance => {
        // setting up progress tracking
        const history = {}
        formInstance.allPages?.forEach((page, idx) => {
            if (!page.component.hideLabel) {
                history[idx] = idx
            }
        })
        setSectionHistory(history)
    }

    return (
        <Box className="fill-form">
            <FormRenderer
                submission={formSubmission}
                definition={formDefinition?.attributes?.formDefinition}
                options={{
                    ...options.formOptions,
                    renderMode,
                }}
                page={pageParam}
                logo={logo}
                scriptUrl={script}
                loading={isLoading()}
                submitted={submitted}
                error={error}
                onSubmit={handleSubmit}
                onNewSubmission={newSubmissionHandler}
                onPrevPage={handlePageChange}
                onNextPage={handlePageChange}
                confirmationPage={
                    formDefinition?.attributes?.formDefinition?.confirmationPage || ''
                }
                hideProgressBar={!!formDefinition?.attributes?.formDefinition?.hideProgressBar}
                onCancel={cancelSubmissionHandler}
                onRestart={restartSubmissionHandler}
                onWizardPageSelected={handleOnWizardPageSelected}
                formReady={onFormReady}
                onFormInstanceRef={ref => {
                    const progressPage = formSubmission?.metadata?.progress?.page_id
                    if (ref.current && progressPage) {
                        // Resume progress at the correct page, when possible
                        ref.current.setPage(progressPage)
                        console.log('onFormInstanceRef: resumed at page', progressPage)
                    }
                }}
            />
        </Box>
    )
}
export default FillForm
